var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "control"
  }, [_c('img', {
    staticClass: "start-img",
    attrs: {
      "src": "https://keyang2.tuzuu.com/uuo/ydj/e2b46b4ebc393780a2e3e76a0b7f7b68.png"
    }
  }), _c('div', {
    staticClass: "control-footer"
  }, [_c('img', {
    staticClass: "footer-img",
    attrs: {
      "src": "https://keyang2.tuzuu.com/uuo/ydj/d29b67708a9c2c477595e22525f4fa37.png"
    }
  }), _c('RouterLink', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "start-btn",
    attrs: {
      "to": "/ydj/active"
    }
  }, [_vm._v(" 开启健康生活 ")])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };