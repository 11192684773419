
import wxShare from "@/wechat/weChatTile";

export default {
    data() {
        return {
            show: false
        }
    },
    async created() {
        const res = await axios.get('/ydj/check_is_activation')
        if (res.data.is_activation === 1) {
            await this.$router.replace("/ydj/person-interests")
        } else {
            this.show = true
        }
    },
    mounted() {
        wxShare({
            title: '一站式健康管理服务平台',
            desc:'康来家园，中国健康管理领导者',
            link:window.location.origin + '/ydj',
        })
    }
}


